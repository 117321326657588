import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
import { brandService } from "./brand.service";

class BrandActionService {
  public getGeneralActions(
    csvCallback: () => void,
    filtersCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-file-csv",
        action: csvCallback
      },
      {
        icon: "fal fa-plus",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "brand-add"
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createBrands
          )
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent",
          action: brandService.searchEvent()
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: store.getters["PermissionsModule/hasPermission"](
          policyList.modifyBrands
        )
          ? "fal fa-pen"
          : "fal fa-eye",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: store.getters["PermissionsModule/hasPermission"](
              policyList.modifyBrands
            )
              ? "brand-edit"
              : "brand-view",
            params: {
              id: arg[0].id
            }
          });
        }
      },
      {
        icon: "",
        switch: "is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBrands
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("brand.brands_toggle_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            try {
              await brandService.toggleBrand(arg);
              store
                .dispatch("BrandModule/loadBrands", arg.item)
                .finally(arg.unselectModal);
            } catch (e) {
              EventBus.$emit("unSelectedModalActive");
              arg.unselectModal();
            }
          },
          modalCancelText: "No"
        }
      }
    ];
  }

  public getMultiActions(): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action(args: any) {
          store.dispatch("BrandModule/enableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBrands
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(args: any) {
          store.dispatch("BrandModule/disableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBrands
          )
      }
    ];
  }
}

export const brandActionService = new BrandActionService();
