import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { Brand } from "@/interfaces/brand";
import { HttpQuery } from "@/interfaces/httpQuery";
import { pusherEvents } from "@/internal";
import { BrandTableMetadata } from "@/metadata/brands";
import { brandActionService } from "@/services/brand.action.service";
import { brandService } from "@/services/brand.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BrandList.template.vue";

const namespace: string = "BrandModule";
@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(brandService)
export default class BrandsListComponent extends Vue {
  @Getter("brands", { namespace })
  public brands!: Brand[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Action("loadBrands", { namespace })
  public getBrandAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public headers: TableHeader[] = BrandTableMetadata;
  public showFilters = false;
  public enableMultiActions = true;
  public activeFilter = false;
  public generalActions: ActionSubheader[] = brandActionService.getGeneralActions(
    this.openCsvModal,
    this.filtersCallback
  );
  public rowActions: TableAction[] = brandActionService.getRowActions();
  public multiActions: TableAction[] = brandActionService.getMultiActions();
  public paginate = brandService.paginationAction();

  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "brands" }
    );
  }

  public filter(header: TableHeader) {
    brandService.sortQuery(header);
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }

  public async triggerSearch(query?: HttpQuery) {
    brandService.setQuery(query);
    this.getBrandAction();
  }

  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */

  public onbrandFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerSearch(param);
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  public mounted() {
    this.setPageNav({
      title: "brand.brands",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.getBrandAction();
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableBrands
    );
    this.$changes.watch(pusherEvents.brandTouched, this.getBrandAction);

    this.headers.map(h => {
      h.label = this.$t(h.label).toString();
      return h;
    });
  }

  protected beforeDestroy() {
    brandService.resetQuery();
  }
}
