import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const BrandTableMetadata: TableHeader[] = [
  {
    label: i18n.t("logo").toString(),
    value: "logo_url",
    fieldComponent: TableFieldType.image,
    class: "tdt__headers__fieldImage"
  },
  {
    label: i18n.t("name").toString(),
    value: "name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("vendors_label").toString(),
    value: "vendorsList",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__moreLong",
    sortable: true,
    conditionalClassFn: arg => {
      arg.vendorsList = arg.vendors
        .map(({ name }: { name: string }) => name)
        .toString();
    }
  }
];

export const BrandProductsTableMetadata: TableHeader[] = [
  {
    label: i18n.t("name").toString(),
    value: "name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    label: i18n.t("batch_type").toString(),
    value: "batch_type.name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__moreLong",
    sortable: false
  },
  {
    label: i18n.t("product_category").toString(),
    value: "category.name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__moreLong",
    sortable: false
  }
];

export const BrandBatchesTableMetadata: TableHeader[] = [
  {
    label: i18n.t("brand.batch_id").toString(),
    value: "batch_uid",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    label: i18n.t("brand.product").toString(),
    value: "name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__moreLong",
    sortable: false
  },
  {
    label: i18n.t("brand.remaining_quantity").toString(),
    // remaining_weight is a parsed field added on brand.actions.ts
    value: "remaining_weight",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__moreLong",
    sortable: false
  }
];
